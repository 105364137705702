<template>
  <div class="app-container user-container">
    <div class="add-container">
        <el-button type="primary" @click="doAdd({})" icon="el-icon-plus">添加使馆</el-button>
    </div>

    <!-- 表格区域  -->
    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
    >
      <el-table-column label="序号" type="index" width="100" align="center"></el-table-column>
      <el-table-column label="使馆名称" min-width="250" prop="name" />
      <el-table-column label="简称" width="120" prop="abbr" />
      <el-table-column label="联系人" width="150" prop="conn_user" />
      <el-table-column label="联系邮箱" width="120" align="center" prop="conn_email" />
      <el-table-column label="联系电话" width="120" align="center" prop="conn_phone" />
      <el-table-column label="所在地址" min-width="200" align="center" prop="address" />
      <el-table-column label="币种" width="120" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pay_type }}({{ row.pay_type_name }})</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="doAdd(row)">编辑</el-link>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm
            title="您确定要删除该使馆吗？"
            @confirm="doDelete(row)"
          >
            <el-link type="danger" slot="reference">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.page_size"
      :total="total"
      style="padding: 32px 0;"
      layout="total, prev, pager, next, jumper"
      @current-change="fetchData"/>

    <!-- 添加类别 -->
    <el-dialog
      :title="postData.id > 0 ? '编辑使馆' : '添加使馆'"
      :visible.sync="dialogVisible"
      destroy-on-close
      :key="dialogIndex"
      @close="dialogIndex++"
    >
      <el-form label-width="120px" :model="postData" :rules="rules" ref="postForm">
        <el-form-item label="使馆名称：" prop="name">
          <el-input v-model="postData.name" placeholder="使馆名称" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="简称：" prop="abbr">
          <el-input v-model="postData.abbr" placeholder="使馆简称（用于面单发件人信息）" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="结算币种：" prop="pay_type">
          <el-select v-model="postData.pay_type" placeholder="币种选择" style="width: 40%">
            <el-option v-for="item in payType" :key="item.value" :value="item.value" :label="`${item.value}(${item.name})`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人：" prop="conn_user">
          <el-input v-model="postData.conn_user" placeholder="联系人" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="conn_phone">
          <el-input v-model="postData.conn_phone" placeholder="联系电话" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱：" prop="conn_email">
          <el-input v-model="postData.conn_email" placeholder="联系邮箱" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="所在地址：" prop="address">
          <el-input v-model="postData.address" placeholder="所在地址" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="派送费设置：" prop="delivery_type" required>
          <div v-for="(item, index) in postData.delivery_type || []" :key="index" class="flex-view flex-aic mbm">
            <el-input v-model="item.name" placeholder="输入费用名称" style="width:150px" class="mrm"></el-input>
            <el-input-number v-model="item.fee" placeholder="费用" :min="0"></el-input-number>
            <el-link type="danger" class="mlm" @click="removeType(index)">删除</el-link>
          </div>
          <el-button type="default" icon="el-icon-plus" @click="addType">添加一项</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit(false)" :loading="uploading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import api from '@/api/dipm'
import { payType } from '@/utils/constants'
import { mapGetters, mapState } from 'vuex'
const listQuery = {
}
export default {
  data() {
    return {
      listQuery: { ...listQuery },
      loading: false,
      list: [{}],
      total: 0,
      payType,

      postData: {
        delivery_type: []
      },
      dialogVisible: false,
      dialogIndex: 0,
      uploading: false,
      rules: {
        name: [{ required: true, message: '使馆名称必填' }],
        abbr: [{ required: true, message: '必填项' }],
        pay_type: [{ required: true, message: '结算币种必选' }],
        delivery_type: [{ required: true, message: '派送费设置不能为空' }],
      },
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['user']),
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      api.getList()
      .then(res => {
        this.list = res.list || []
        this.total = res.total
        this.loading = false
      })
    },
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData()
    },
    doAdd(row) {
      this.dialogVisible = true
      this.postData = { ...row }
    },
    onSubmit() {
      this.$refs.postForm.validate(valid => {
        if (!valid) return false
        // 校验派送费设置
        if (!this.postData.delivery_type || !this.postData.delivery_type.length) {
          this.$message.error('派送费设置不能为空');
          return false;
        }
        for (let index = 0; index < this.postData.delivery_type.length; index++) {
          const v = this.postData.delivery_type[index]
          if (!v.name || !v.fee) {
            this.$message.error(`第${index + 1}项设置中的信息不完整`)
            return false
          }
        }

        this.uploading = true
        let body = { ...this.postData, pay_type_name: this.payType.find(v => v.value === this.postData.pay_type).name }
        if (!this.isAdmin) {
          body.dipm_id = this.user.diplomaticDTO.id || 0
        }

        if (this.postData.id > 0) {
          api.update(this.postData.id, body)
          .then(() => {
            this.$message.success('修改成功')
            this.list = this.list.map(v => {
              if (v.id === this.postData.id) {
                v = { ...v, ...body }
              }
              return v
            })
            this.uploading = false
            this.dialogVisible = false
          })
          .catch(() => {
            this.uploading = false
          })
          return
        }
        api.add(body)
        .then(id => {
          this.$message.success('添加成功')
          this.list = [ ...this.list, { ...body, id } ]
          this.uploading = false
          this.dialogVisible = false
        })
        .catch(() => {
          this.uploading = false
        })
      })
    },
    doDelete(row) {
      api.del(row.id)
      .then(() => {
        this.$message.success('删除成功')
        this.list = this.list.filter(v => v.id !== row.id)
      })
    },
    removeType(index) {
      this.postData.delivery_type = this.postData.delivery_type.filter((v, i) => i !== index)
    },
    addType() {
      this.postData = {
        ...this.postData,
        delivery_type: [ ...(this.postData.delivery_type || []), {} ]
      }
    }
  }
}
</script>
