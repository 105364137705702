export const userType = []

export const payType = [
  { value: 'RMB', name: '人民币' },
  { value: 'SEK', name: '克朗' },
  { value: 'EUR', name: '欧元' },
  { value: 'USD', name: '美元' },
  { value: 'GBP', name: '英镑' },
]

export const printStatus = [
  { value: '', text: '未选择' },
  { value: 0, text: '未打印' },
  { value: 1, text: '已打印' },
]

// 派送费支付状态
export const deliveryPayStatus = [
  { value: '', text: '未选择' },
  { value: 0, text: '未支付' },
  { value: 1, text: '已支付' },
]

// 使馆费支付状态
export const dipmPayStatus = [
  { value: '', text: '未选择' },
  { value: 0, text: '未支付' },
  { value: 1, text: '已支付' },
]

// 物流状态
export const orderLogStatus = [
  { value: '', text: '未选择' },
  { value: 0, text: '待揽件' },
  { value: 1, text: '已揽件' },
  { value: 2, text: '待派送' },
  { value: 3, text: '派送中' },
  { value: 4, text: '已签收' },
]

// 申请单状态
export const orderStatus = [
  { value: '', text: '未选择' },
  { value: 999, text: '未完成' },
  { value: 10, text: '待审核' },
  { value: 20, text: '待付快递费' },
  { value: 30, text: '揽件中' },
  { value: 40, text: '处理中' },
  { value: 50, text: '待付使馆费' },
  { value: 60, text: '派送中' },
  { value: 70, text: '已完成' },
  { value: 99, text: '已取消' },
]

// 审核状态
export const orderReviewStatus = [
  { value: '', text: '未选择' },
  { value: 1, text: '待审核' },
  { value: 2, text: '审核通过' },
  { value: 3, text: '审核失败' },
]

// 物流状态
export const logisticsStatus = [
  { value: '', text: '未选择' },
  { value: 0, text: '待揽件' },
  { value: 1, text: '已揽件' },
  { value: 2, text: '待派送' },
  { value: 3, text: '派送中' },
  { value: 4, text: '已签收' },
  { value: 5, text: '已分拣' },
]
